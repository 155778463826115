import React from "react"

import { Hero } from "../../components/blog/hero"
import { PostSidebar } from "../../components/blog/post-sidebar"
import { Breadcrumbs, BreadcrumbsItem } from "../../components/breadcrumbs"
import { Container } from "../../components/container"
import SEO from "../../components/seo"
import img1 from "./images/7-min.jpg"
import styles from "./post-grid.module.css"

const VitaminB = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={"Vitaminy skupiny B | NutritionPro"}
        description={
          "Vitaminy jsou biologicky aktivní látky, které si lidský organismus, až na výjimky, není schopen sám vytvořit. Jsou to látky esenciální, to znamená, že je naše tělo potřebuje a je nutné je přijímat ve stravě. Působí například jako antioxidanty, v těle však fungují především jako koenzymy."
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: "24px 0" }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/zdrave-udrzitelne-hubnuti">
            Vitaminy skupiny B
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="Vitaminy skupiny B" date="08.02.2023" />
            <div>
              <h5 className={styles.postTitle}>Krátce o vitaminech</h5>
              <p className={styles.postText}>
                Vitaminy jsou biologicky aktivní látky, které si lidský
                organismus, až na výjimky, <b>není schopen sám vytvořit.</b>{" "}
                Jsou to látky esenciální, to znamená, že je naše tělo{" "}
                <b>potřebuje a je nutné je přijímat ve stravě.</b> Působí
                například jako <b>antioxidanty,</b> v těle však fungují
                především jako <b>koenzymy.</b>
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Pokud chcete mít v jídelníčku dostatek vitaminů a
                potřebných živin, zkuste{" "}
                <a href="https://nutritionpro.cz/" target="_blank">
                  NutritionPro krabičky.
                </a>
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: "40px" }}
                alt="img1"
              />
              <h5 className={styles.postTitle}>Vitaminy skupiny B</h5>
              <p className={styles.postText}>
                Vitaminy skupiny B, takzvaný b-komplex, patří do skupiny
                vitaminů rozpustných ve vodě. Potravinové zdroje těchto vitaminů
                jsou velmi podobné, proto se{" "}
                <b>
                  při dostatku jednoho vitaminů z této skupiny předpokládá
                  dostatek všech.
                </b>{" "}
                Ztráty u přípravy potravin jsou především výluhem. Vitaminy B
                jsou <b>zásadní pro energetický metabolismus,</b> jelikož jsou
                součástí enzymů, které jsou odpovědné za získávání energie z
                živin. Dostatek vitaminů B je, mimo jiné,{" "}
                <b>výhodný i pro hubnutí.</b> Některé vitaminy ze skupiny B
                přímo ovlivňují úroveň spalování během fyzické aktivity. A
                jelikož je zdrojem vitaminů B i pečivo a přílohy, určitě není
                vhodné je vyřazovat, a to ani při redukční dietě.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Pokud vás zajímá, jak zhubnout zdravě a udržitelně,{" "}
                <a
                  href="https://nutritionpro.cz/blog/zdrave-udrzitelne-hubnuti/"
                  target="_blank"
                >
                  přečtěte si tento článek.
                </a>
              </p>

              <p className={styles.postText}>
                V dnešním článku si shrneme{" "}
                <b>základní funkce jednotlivých vitaminů</b> ze skupiny B, ve
                kterých potravinám tyto vitaminy najdeme a také co se děje při
                jejich nedostatku. Jdeme na to!
              </p>

              <h5 className={styles.postTitle}>Vitamin B1 - thiamin</h5>

              <h6 className={styles.postSubTitle}>Proč je důležitý?</h6>
              <p className={styles.postText}>
                Vitamin B1, nebo také thiamin, hraje{" "}
                <b>důležitou roli v metabolismu sacharidů a aminokyselin,</b>{" "}
                podporuje nervový systém, ovlivňuje svalový tonus, snižuje
                deprese, únavu, svalové křeče a úzkost a podporuje správnou
                funkci srdce. Zajímavostí je, že se thiamin využívá při léčbě
                alkoholismu a jiných závislostí.
              </p>

              <h6 className={styles.postSubTitle}>
                Ve kterých potravinách ho najdete?
              </h6>
              <p className={styles.postText}>
                V nízkých koncentracích <b>ve velkém množství potravin,</b>{" "}
                např. v celozrnných obilovinách, luštěninách, mase, ovoci,
                bramborách nebo ve vejcích.
              </p>

              <h6 className={styles.postSubTitle}>Nedostatek</h6>
              <p className={styles.postText}>
                Známky nedostatku se dostaví přibližně za měsíc nepřijímání.
                Důsledkem je <b>nevolnost, podrážděnost,</b> zmatenost nebo
                nemoc beri-beri.
              </p>

              <p className={styles.postText}>
                Při přípravě pokrmů se z potravin může vytratit až 70 %
                thiaminu, nejčastěji vyluhováním. Někdy se přidává do masa jako
                aditivum, ať už pro obohacení nebo kvůli chuti. Je také vůbec{" "}
                <b>prvním objeveným</b> ve vodě rozpustným <b>vitaminem.</b>
              </p>

              <h5 className={styles.postTitle}>Vitamin B2 - riboflavin</h5>

              <h6 className={styles.postSubTitle}>Proč je důležitý?</h6>
              <p className={styles.postText}>
                Riboflavin je důležitý <b>pro získávání energie z živin -</b>{" "}
                sacharidů, tuků a bílkovin, dále pro správnou funkci imunitního
                a nervového systému nebo pro zdravé oči a kůži. Také jej lze
                využít při léčbě migrény u dospělých.
              </p>

              <h6 className={styles.postSubTitle}>
                Ve kterých potravinách ho najdete?
              </h6>
              <p className={styles.postText}>
                Mléko a mléčné výrobky, maso, celozrnné obiloviny, kvasnice.
              </p>

              <h6 className={styles.postSubTitle}>Nedostatek</h6>
              <p className={styles.postText}>
                Při nedostatku hrozí poruchy kůže a sliznic, záněty nebo únava.
              </p>

              <h5 className={styles.postTitle}>Vitamin B3 - Niacin</h5>

              <h6 className={styles.postSubTitle}>Proč je důležitý?</h6>
              <p className={styles.postText}>
                Niacin je nezbytný <b>pro získávání energie ze sacharidů,</b>{" "}
                podporuje správnou funkci nervového systému, zdravý spánek a
                zdravou pokožku.
              </p>

              <h6 className={styles.postSubTitle}>
                Ve kterých potravinách ho najdete?
              </h6>
              <p className={styles.postText}>
                Maso, vnitřnosti, kvasnice, celozrnné obiloviny a brambory.{" "}
                <b>Lidské tělo ho též umí syntetizovat z tryptofanu,</b> což je
                esenciální aminokyselina, která se nachází v určitých
                potravinách, které obsahují bílkoviny.
              </p>

              <h6 className={styles.postSubTitle}>Nedostatek</h6>
              <p className={styles.postText}>
                Nedostatečný příjem niacinu může způsobit nemoc zvanou{" "}
                <b>pelagra,</b> jinak také nemoc 3D, která souvisí s
                psychickými, trávicími a kožními potížemi. Nedostatek B3 může
                také způsobit poškození kůže, změny sliznic úst, únavu, slabost
                nebo poruchy trávení.
              </p>

              <h5 className={styles.postTitle}>
                Vitamin B5 - Pantothenová kyselina
              </h5>

              <h6 className={styles.postSubTitle}>Proč je důležitý?</h6>
              <p className={styles.postText}>
                Vitamin B5 je nutný pro <b>růst a reprodukci,</b> také pro
                metabolismus sacharidů, tuků a aminokyselin, produkci hormonů a
                tvorbu červených krvinek.
              </p>

              <h6 className={styles.postSubTitle}>
                Ve kterých potravinách ho najdete?
              </h6>
              <p className={styles.postText}>
                Játra, ryby, vejce, mléko, zelí, rajčata, kvasnice.
              </p>

              <h6 className={styles.postSubTitle}>Nedostatek</h6>
              <p className={styles.postText}>
                Nedostatek pantothenové kyseliny je <b>poměrně vzácný,</b> pokud
                k němu však dojde, projevuje se bolestmi hlavy, únavou či
                apatií.
              </p>

              <h5 className={styles.postTitle}>Vitamin B6 - pyridoxin</h5>

              <h6 className={styles.postSubTitle}>Proč je důležitý?</h6>
              <p className={styles.postText}>
                <b>Ovlivňuje funkci nervového a imunitního systému,</b> je
                důležitý pro správnou funkci červených krvinek (produkce
                hemoglobinu a přenos kyslíku), <b>ovlivňuje metabolismus</b>{" "}
                bílkovin a syntézu neurotransmiterů, jako je serotonin,
                melatonin, dopamin a noradrenalin, které mají vliv na psychiku a
                stres, pomáhá rozvoji a správné funkci mozku, příznivě působí na
                záněty a podílí se na regulaci normální hladiny glukózy v krvi
                (nedostatek B6 je častý u cukrovkářů).{" "}
                <b>V těhotenství je vitamin B6 také velmi důležitý,</b> a to pro
                rozvoj centrální nervové soustavy plodu. Pomáhá též při
                nevolnostech a má vliv na zdraví a vzhled kůže.
              </p>

              <h6 className={styles.postSubTitle}>
                Ve kterých potravinách ho najdete?
              </h6>
              <p className={styles.postText}>
                Vitamin B6 je <b>nejlépe využitelný z živočišných zdrojů,</b>{" "}
                jako je maso, ryby, vnitřnosti a vaječný žloutek, dále ho
                najdete například v ořeších, celozrnných výrobcích nebo
                bramborách, využitelnost je zde však o poznání horší.
              </p>

              <h6 className={styles.postSubTitle}>Nedostatek</h6>
              <p className={styles.postText}>
                Projevy nedostatku vitaminu B6 jsou{" "}
                <b>odlišné u dětí a dospělých.</b> U dětí může způsobit
                například křeče, u dospělých kožní poruchy, hypochromní anémie
                nebo deprese.
              </p>

              <h5 className={styles.postTitle}>
                Vitamin B9 - listová kyselina
              </h5>

              <h6 className={styles.postSubTitle}>Proč je důležitý?</h6>
              <p className={styles.postText}>
                Mnoho z vás nejspíše zná vitamin B9 především ve spojitosti s
                těhotenstvím. Jeho příjem je ovšem stejně tak důležitý pro
                kteroukoliv jinou skupinu, než jsou těhotné ženy. Listová
                kyselina je <b>nezbytná pro tvorbu červených krvinek a DNA</b> a
                podporuje funkci imunitního systému. V těhotenství se podílí na
                správném vývoji plodu a její příjem snižuje riziko závažných
                postižení plodu, jako je například rozštěp páteře. Suplementace
                listové kyseliny se doporučuje alespoň 1 měsíc před plánovaným
                početím.
              </p>

              <h6 className={styles.postSubTitle}>
                Ve kterých potravinách ho najdete?
              </h6>
              <p className={styles.postText}>
                Stejně jako u B6, i zde platí skutečnost, že využitelnost
                vitaminu je vyšší u potravin živočišného původu. Najdete jej
                například v listové zelenině, játrech, mléce, vejcích,
                luštěninách nebo citrusových plodech.
              </p>

              <h6 className={styles.postSubTitle}>Nedostatek</h6>
              <p className={styles.postText}>
                Při nedostatku hrozí anémie, hyperhomocysteinémie nebo poruchy
                sliznic. U těhotných hrozí poruchy vývoje plodu (poruchy růstu,
                rozštěpy), vyšší riziko potratu nebo předčasný porod.
              </p>

              <h5 className={styles.postTitle}>Vitamin B12 - kobalamin</h5>

              <h6 className={styles.postSubTitle}>Proč je důležitý?</h6>
              <p className={styles.postText}>
                Jako většina kolegů ze skupiny, i vitamin B12 je důležitý pro
                metabolismus živin, dále pro krvetvorbu, tvorbu genetického
                materiálu, a také pro normální funkci nervového systému.
              </p>

              <h6 className={styles.postSubTitle}>
                Ve kterých potravinách ho najdete?
              </h6>
              <p className={styles.postText}>
                Vitamin B12 je známý především u osob, které se stravují
                alternativně, konkrétně u veganů.{" "}
                <b>
                  Využitelný vitamin B12 najdeme totiž pouze v živočišných
                  zdrojích,
                </b>{" "}
                tedy v mase, vnitřnostech, rybách, mléce a vejcích. U veganů je
                suplementace tohoto vitaminu nutná. Kobalaminy lze najít i v
                rostlinných zdrojích, ty jsou pro nás ovšem méně využitelné.
                Přebytek B12 se následně ukládá v játrech.
              </p>

              <h6 className={styles.postSubTitle}>Nedostatek</h6>
              <p className={styles.postText}>
                Poškození nervové soustavy, percinózní anémie,
                hyperhomocysteinémie, zmatenost a podrážděnost.
              </p>

              <h5 className={styles.postTitle}>Vitamin B7/H - biotin</h5>

              <h6 className={styles.postSubTitle}>Proč je důležitý?</h6>
              <p className={styles.postText}>
                Vitamin B7, známý též jako vitamin H nebo biotin, je{" "}
                <b>opět velmi důležitý pro metabolismus živin,</b> tedy
                získávání energie ze sacharidů, tuků a bílkovin. Stará se o{" "}
                <b>zdravé vlasy, nehty a kůži</b> a pomáhá vyrovnávat hladinu
                krevního cukru.
              </p>

              <h6 className={styles.postSubTitle}>
                Ve kterých potravinách ho najdete?
              </h6>
              <p className={styles.postText}>
                Játra, ledviny, kvasnice, žloutek, mléko, některé luštěniny
                (arašídy, sója), čokoláda, špenát. Částečně se také tvoří ve
                střevním mikrobiomu.
              </p>

              <h6 className={styles.postSubTitle}>Nedostatek</h6>
              <p className={styles.postText}>
                Nedostatek vitaminu B7 je vzácný a má{" "}
                <b>nespecifické příznaky,</b> jako jsou poruchy kůže, svalová
                slabost, zvracení, deprese nebo padání vlasů.
              </p>

              <h5 className={styles.postTitle}>Závěrem</h5>

              <p className={styles.postText}>
                Je nejspíše patrné, že vitaminy skupiny B jsou pro naše tělo{" "}
                <b>velmi zásadní</b> a je třeba nebrat jejich příjem na lehkou
                váhu. Pokud chcete mít jistotu, že je váš příjem těchto vitaminů
                dostatečný, vsaďte na{" "}
                <a href="https://nutritionpro.cz/" target="_blank">
                  naše krabičky,
                </a>{" "}
                které jsou <b>vitaminy nabité.</b>
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default VitaminB
